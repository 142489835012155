// Node Modules
import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";

// Types
import { IHoursOfOperationDetails } from "@/interfaces/splash-screen";
import IScheduleContext from "@/interfaces/context/schedules/IScheduleContext";
import IScheduleContextProvider from "@/interfaces/context/schedules/IScheduleContextProvider";
import { hoursOfOperationParsingUtils } from "@/scripts/hourOfOperationParsingUtils"

import {
  calculateTimeOutForIntervalScheduleChecks,
  getHoursOfOperationByType
} from "@/scripts/hoursOfOperationHelper";

const SchedulesContext = createContext<IScheduleContext | undefined>(undefined);

const useSchedulesContext = () => {
  const context = useContext(SchedulesContext);
  if (!context) {
    throw new Error("useHoursOfOperationContext must be used within a NSCHoursOfOperationContextProvider");
  }

  return context;
};

const SchedulesContextProvider = ({
  children,
}: IScheduleContextProvider) => {
  const [gateSchedule, setGateSchedule] = useState<IHoursOfOperationDetails>();
  const [staffingSchedule, setStaffingSchedule] = useState<IHoursOfOperationDetails>();
  const [officeSchedule, setOfficeSchedule] = useState<IHoursOfOperationDetails>();
  const [nscSchedule, setNscSchedule] = useState<IHoursOfOperationDetails>();
  const [isGateCurrentlyOpen, setIsGateCurrentlyOpen] = useState<boolean>(false);
  const [isNscCurrentlyOpen, setIsNscCurrentlyOpen] = useState<boolean>(false);
  const [isStaffingCurrentlyOpen, setIsStaffingCurrentlyOpen] = useState<boolean>(false);
  const [isOfficeCurrentlyOpen, setIsOfficeCurrentlyOpen] = useState<boolean>(false);
  const schedulesCheckInterval = useRef(null);
  const schedulesCheckTimeout = useRef(null);
  
  const setSchedules = (schedules: IHoursOfOperationDetails[]) => {
    setGateSchedule(getHoursOfOperationByType(schedules, "gate"));
    setOfficeSchedule(getHoursOfOperationByType(schedules, "office"));
    setStaffingSchedule(getHoursOfOperationByType(schedules, "staffing"));
    setNscSchedule(getHoursOfOperationByType(schedules, "nsc_customer_support"));
  }

  useEffect(() => {
    const allSchedulesAreSet = nscSchedule && gateSchedule && officeSchedule && staffingSchedule;
    const intervalHasBeenSet = schedulesCheckTimeout.current && schedulesCheckInterval.current;

    if (allSchedulesAreSet && !intervalHasBeenSet) {
      checkStatusOfAllSchedules();
      setSchedulesChecksOnIntervalTime();
    }
  }, [
    gateSchedule,
    nscSchedule,
    officeSchedule,
    staffingSchedule,
  ]);

  const checkStatusOfAllSchedules = () => {
    const isGateClosed = hoursOfOperationParsingUtils.isScheduleInCloseHours(gateSchedule);
    setIsGateCurrentlyOpen(!isGateClosed);

    const isNscClosed = hoursOfOperationParsingUtils.isScheduleInCloseHours(nscSchedule);
    setIsNscCurrentlyOpen(!isNscClosed);

    const isStaffingClosed = hoursOfOperationParsingUtils.isScheduleInCloseHours(staffingSchedule);
    setIsStaffingCurrentlyOpen(!isStaffingClosed);

    const isOfficeClosed = hoursOfOperationParsingUtils.isScheduleInCloseHours(officeSchedule);
    setIsOfficeCurrentlyOpen(!isOfficeClosed);
  }

  const setSchedulesChecksOnIntervalTime = () => {
    const everyXMinutesOfAnHour = 30;
    
    const intervalScheduleChecks = calculateTimeOutForIntervalScheduleChecks(everyXMinutesOfAnHour);

    schedulesCheckTimeout.current = setTimeout(() => {
      checkStatusOfAllSchedules();
      schedulesCheckInterval.current = setInterval(checkStatusOfAllSchedules, intervalScheduleChecks.intervalTimeInMilliseconds);
    }, intervalScheduleChecks.timeOutForIntervalStart);
  };

  useEffect(() => {
    return () => {
      clearInterval(schedulesCheckInterval.current);
      clearTimeout(schedulesCheckTimeout.current);
    }
  }, []);

  const contextValues: IScheduleContext = {
    gateSchedule,
    isGateCurrentlyOpen,
    isStaffingCurrentlyOpen,
    isOfficeCurrentlyOpen,
    isNscCurrentlyOpen,
    nscSchedule,
    officeSchedule,
    setSchedules,
    staffingSchedule,
  };

  return (
    <SchedulesContext.Provider
      value={contextValues}
    >
      {children}
    </SchedulesContext.Provider>
  );
};

export {
  SchedulesContext,
  SchedulesContextProvider,
  useSchedulesContext,
};
