// Constants
import { callStatuses } from "@/scripts/constant-types/voice/twilioConstants";

// Contexts
import { useCallContext } from "@/context/CallContext";
import { useSchedulesContext } from "@/context/SchedulesContext";
import { useVideoCallContext } from "@/context/VideoCallContext";

// Enums
import { CustomerSupportFeature } from "@/enums/optimizely/CustomerSupportFeature";

// Node Modules
import { useDecision } from "@optimizely/react-sdk";
import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";

// Types
import ICustomerSupportContext from "@/interfaces/context/customer-support/ICustomerSupportContext";
import ICustomerSupportContextProvider from "@/interfaces/context/customer-support/ICustomerSupportContextProvider";
import {
  getShouldRunCustomerSupportFeature,
  resetHasAttractionLoopJustBeenClosedIfCallIsActive
} from "@/scripts/customerSupportHelper";

const CustomerSupportContext = createContext<ICustomerSupportContext | undefined>(undefined);

const useCustomerSupportContext = () => {
  const context = useContext(CustomerSupportContext);
  if (context === undefined) {
    throw new Error("useCustomerSupportContext must be used within a ICustomerSupportContextProvider");
  }
  return context;
};

const CustomerSupportContextProvider = ({
  children,
}: ICustomerSupportContextProvider) => {
  const [customerSupportFeature, setCustomerSupportFeature] = useState<string>();
  const startCallFunctionRef = useRef<() => void | undefined>(undefined);
  const [runCustomerSupportFeature, setRunCustomerSupportFeature] = useState<boolean>(false);
  const [hasAttractionLoopJustBeenClosed, setHasAttractionLoopJustBeenClosed] = useState<boolean>(false);
  const resetHasAttractionLoopJustBeenClosed = () => setHasAttractionLoopJustBeenClosed(false);

  const [decision] = useDecision(CustomerSupportFeature.Variation, {
    autoUpdate: true,
  });

  const {
    callState,
  } = useCallContext();

  const {
    callState: videoCallState,
    isIncomingCall,
  } = useVideoCallContext();

  const {
    isNscCurrentlyOpen,
    isStaffingCurrentlyOpen,
  } = useSchedulesContext();

  useEffect(() => {
    const isAudioCallStateActive: boolean = callState?.callStatus && callState?.callStatus !== callStatuses.inactive;
    const isVideoCallStateActive: boolean = isIncomingCall || (videoCallState?.callStatus && videoCallState?.callStatus !== callStatuses.inactive);

    let customerSupportFeatureFromOptimizely: string;
    if (decision && decision.enabled) {
      customerSupportFeatureFromOptimizely = decision.variationKey;
    }

    const shouldRunCustomerSupportFeature = getShouldRunCustomerSupportFeature(hasAttractionLoopJustBeenClosed, isAudioCallStateActive, isNscCurrentlyOpen, customerSupportFeatureFromOptimizely, isVideoCallStateActive);
    setRunCustomerSupportFeature(shouldRunCustomerSupportFeature);
    setCustomerSupportFeature(customerSupportFeatureFromOptimizely);
    resetHasAttractionLoopJustBeenClosedIfCallIsActive(isAudioCallStateActive, isVideoCallStateActive, resetHasAttractionLoopJustBeenClosed);
  }, [
    callState,
    decision,
    hasAttractionLoopJustBeenClosed,
    isIncomingCall,
    isNscCurrentlyOpen,
    isStaffingCurrentlyOpen,
    videoCallState,
  ]);

  const contextValues: ICustomerSupportContext = {
    customerSupportFeature,
    resetHasAttractionLoopJustBeenClosed,
    setHasAttractionLoopJustBeenClosed,
    setRunCustomerSupportFeature,
    runCustomerSupportFeature,
    startCallFunctionRef,
  };

  return (
    <CustomerSupportContext.Provider
      value={contextValues}
    >
      {children}
    </CustomerSupportContext.Provider>
  );
};

export {
  CustomerSupportContext,
  CustomerSupportContextProvider,
  useCustomerSupportContext
};
