// Context
import { useLoaderContext } from "@/context/LoaderContext";

// Node Modules
import { useEffect } from "react";
import { useRouter } from "next/router";

const isUrlToBeHandledManually = (url: string): boolean => {
  const routesToHandleManually: string[] = ["/facility-map/"];
  for (const route of routesToHandleManually) {
    if (url.includes(route)) {
      return true;
    }
  }

  return false;
}

const isUrlThatNeedsLoader = (url: string): boolean => {
  if (url === "/") {
    return false;
  }

  const routesThatDontNeedLoader: string[] = [];
  for (const route of routesThatDontNeedLoader) {
    if (url.includes(route)) {
      return false;
    }
  }

  return true;
}

const RoutesHandler = () => {
  const router = useRouter();
  const {
    hideLoader,
    showLoader,
  } = useLoaderContext();

  useEffect(() => {
    const handleStart = (url: string): void => isUrlThatNeedsLoader(url) && (url !== router.asPath) && showLoader();
    const handleComplete = (url: string): void => !isUrlToBeHandledManually(url) && hideLoader();
    const handleOnError = (): void => hideLoader();

    router.events.on("routeChangeStart", handleStart);
    router.events.on("routeChangeComplete", handleComplete);
    router.events.on("routeChangeError", handleOnError);

    return () => {
      router.events.off("routeChangeStart", handleStart);
      router.events.off("routeChangeComplete", handleComplete);
      router.events.off("routeChangeError", handleOnError);
    }
  });

  return (
    <>
    </>
  )
}

export default RoutesHandler;
