// Node Modules
import { useStopwatch } from "react-timer-hook";
import React, {
  MutableRefObject,
  createContext,
  useContext,
  useRef,
  useState,
} from "react";

// Scripts
import { callStatuses } from "@/scripts/constant-types/voice/twilioConstants";

// Types
import ICallInformationContext from "@/interfaces/context/video/ICallInformationContext";
import ICallState from "@/interfaces/twilio/video/ICallState";
import IVideoCallContextProvider from "@/interfaces/context/video/IVideoCallContextProvider";

const callInitialState: ICallState = {
  buttonLabel: "Call Us Now",
  callStatus: callStatuses.inactive,
};

const VideoCallContext = createContext<ICallInformationContext | undefined>(undefined);

const useVideoCallContext = () => {
  const context = useContext(VideoCallContext);
  if (context === undefined) {
    throw new Error("useVideoCallContext must be used within a VideoCallContextProvider");
  }
  return context;
};

const VideoCallContextProvider = ({
  children,
  callStateValue,
  roomValue,
  isIncomingCallValue = false,
}: IVideoCallContextProvider) => {
  const audioRef = useRef<HTMLAudioElement>(null);
  const [callState, setCallState] = useState<ICallState>(callStateValue ?? callInitialState);
  const [hasAgentEnteredTheRoom, setHasAgentEnteredTheRoom] = useState<boolean>(false);
  const [isIncomingCall, setIsIncomingCall] = useState<boolean>(isIncomingCallValue);
  const [isCallDisconnecting, setIsCallDisconnecting] = useState<boolean>(false);
  const [room, setRoom] = useState(roomValue);
  const [taskSid, setTaskSid] = useState<string>(null);
  const [audioElement, setAudioElement] = useState<MutableRefObject<HTMLAudioElement>>(audioRef);

  const stopWatch = useStopwatch({
    autoStart: false,
  });

  const contextValues: ICallInformationContext = {
    audioElement,
    callState,
    hasAgentEnteredTheRoom,
    isCallDisconnecting,
    isIncomingCall,
    room,
    setAudioElement,
    setCallState,
    setHasAgentEnteredTheRoom,
    setIsCallDisconnecting,
    setIsIncomingCall,
    setRoom,
    setTaskSid,
    stopWatch,
    taskSid,
  };

  return (
    <VideoCallContext.Provider
      value={contextValues}
    >
      {children}
    </VideoCallContext.Provider>
  );
};

export {
  VideoCallContext,
  VideoCallContextProvider,
  useVideoCallContext
};
