// Node Modules
import React, {
  createContext,
  useContext,
  useMemo,
  useState,
} from "react";

// Types
import IPaymentInformationContext from "@/interfaces/context/payment/IPaymentInformationContext";
import IPaymentInformationContextProvider from "@/interfaces/context/payment/IPaymentInformationContextProvider";
import OneTimePaymentInformation from "@/classes/payment/OneTimePaymentInformation";

const PaymentInformationContext = createContext<IPaymentInformationContext | undefined>(undefined);
const paymentInformationState = new OneTimePaymentInformation();

const usePaymentInformationContext = () => {
  const context = useContext(PaymentInformationContext);
  if (context === undefined) {
    throw new Error("usePaymentInformationContext must be used within a PaymentInformationContextProvider");
  }

  return context;
};

const PaymentInformationContextProvider = ({
  children,
  value,
}: IPaymentInformationContextProvider) => {
  const [paymentInformation, setPaymentInformation] = useState(value?.paymentInformation ?? paymentInformationState);

  const paymentInformationValue = useMemo(() => ({
    paymentInformation,
    setPaymentInformation,
  }), [paymentInformation, setPaymentInformation]);

  return (
    <PaymentInformationContext.Provider
      value={paymentInformationValue}
    >
      {children}
    </PaymentInformationContext.Provider>
  );
};

export {
  PaymentInformationContext,
  PaymentInformationContextProvider,
  usePaymentInformationContext,
};
