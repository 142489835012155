// Constants
import { featureTogglesStorageKey } from "@/scripts/constant-types/localStorageKeys";

// Node Modules
import React, {
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";

// Services
import {
  getFeatures,
  setLocalStorageObject
} from "@/services/localStorageService";

// Types
import IFeatureToggle from "@/interfaces/device/features/IFeatureToggle";
import IFeatureToggleContext from "@/interfaces/device/features/IFeatureToggleContext";

const featureNames = {
  flex: "FLEX",
}

const FeatureToggleContext = createContext<IFeatureToggleContext | undefined>(undefined);

const useFeatureToggleContext = () => {
  const context = useContext(FeatureToggleContext);
  if (context === undefined) {
    throw new Error("useFeatureToggleContext must be used within a FeatureToggleContextProvider");
  }

  return context;
};

const FeatureToggleContextProvider = ({
  children,
}) => {
  const [featureToggleList, setFeatureToggleList] = useState<IFeatureToggle[]>(getFeatures());

  useEffect(() => {
    setLocalStorageObject(featureTogglesStorageKey, featureToggleList);
  }, [featureToggleList]);

  const featureToggleContextValue: IFeatureToggleContext = {
    featureToggleList,
    setFeatureToggleList,
  };

  return (
    <FeatureToggleContext.Provider
      value={featureToggleContextValue}
    >
      {children}
    </FeatureToggleContext.Provider>
  );
};

export {
  featureNames,
  FeatureToggleContext,
  FeatureToggleContextProvider,
  useFeatureToggleContext,
};
