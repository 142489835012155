const resetHasAttractionLoopJustBeenClosedIfCallIsActive = (
  isAudioCallStateActive: boolean,
  isVideoCallStateActive: boolean,
  resetHasAttractionLoopJustBeenClosed: () => void
): void => {
  if (isAudioCallStateActive || isVideoCallStateActive) {
    resetHasAttractionLoopJustBeenClosed();
  }
}

const getShouldRunCustomerSupportFeature = (
  hasAttractionLoopJustBeenClosed: boolean,
  isAudioCallStateActive: boolean,
  isNSCCurrentlyOpen: boolean,
  customerSupportFeature: string,
  isVideoCallStateActive: boolean
): boolean => {
  const isCustomerSupportAvailable = isNSCCurrentlyOpen;
  const areCallsInactive = !isAudioCallStateActive && !isVideoCallStateActive;
  const isFeatureEnabled = !!customerSupportFeature;

  return hasAttractionLoopJustBeenClosed && isCustomerSupportAvailable && areCallsInactive && isFeatureEnabled;
};

export {
  resetHasAttractionLoopJustBeenClosedIfCallIsActive,
  getShouldRunCustomerSupportFeature,
};

