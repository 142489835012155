"use client";

// Node Modules
import { useEffect } from "react";

// Types
import ICameraProps from "@/interfaces/global-components/camera/ICameraProps";
import {
  StatusType,
  logOnDataDog
} from "@/components/services/dataDogLoggingService";

const Camera = ({
  canvasElementRef,
  mediaStream,
  mediaStreamConstraints,
  setIsCameraAccessibleFromThisBrowser,
  setMediaStream,
  videoElementProps = {
    height: 720,
    isVisible: false,
    width: 1080,
  },
  videoElementRef,
}: ICameraProps) => {
  const constraints: MediaStreamConstraints = mediaStreamConstraints ?? {
    video: {
      frameRate: {
        ideal: 20,
        max: 25,
      },
      height: videoElementProps.height,
      width: videoElementProps.width,
    },
  };

  const stopMediaStreamTracks = (mediaStream: MediaStream | null) => {
    if (mediaStream) {
      mediaStream.getTracks().forEach((track: MediaStreamTrack) => {
        track.stop();
      });
    }
  };

  useEffect(() => {
    /**
     * This function asks the user for permission to use the camera and starts the camera stream.
     * If the user declines the permission, it throws an error.
     */
    const enableVideoStream = async (): Promise<void> => {
      try {
        const stream: MediaStream = await navigator.mediaDevices.getUserMedia(constraints);
        logOnDataDog("CAMERA_STREAM_STARTED", StatusType.info);
        setMediaStream(stream);
        setIsCameraAccessibleFromThisBrowser(true);
      } catch (error) {
        const devices: MediaDeviceInfo[] = await navigator.mediaDevices.enumerateDevices();
        logOnDataDog("CAMERA_STREAM_FAILED", StatusType.info, {
          devices,
          error,
        });
        setIsCameraAccessibleFromThisBrowser(false);
      }
    };

    enableVideoStream();
  }, []);

  useEffect(() => {
    const hasMediaStreamStarted: boolean = !!videoElementRef.current && !!mediaStream;

    if (hasMediaStreamStarted) {
      const showCameraFeedInVideoElement = async () => {
        videoElementRef.current!.srcObject = mediaStream;
        await videoElementRef.current!.play();
      }
      showCameraFeedInVideoElement();
    }

    return () => stopMediaStreamTracks(mediaStream);
  }, [mediaStream]);

  return (
    <div>
      <video
        autoPlay
        muted
        ref={videoElementRef}
        style={{
          visibility: "hidden",
          transform: "scaleX(-1)",
          position: "absolute",
          top: 0,
          left: 0,
          zIndex: -100,
        }}
      />
      <canvas
        height={videoElementProps.height}
        ref={canvasElementRef}
        style={{
          visibility: !videoElementProps.isVisible
            ? "hidden"
            : "visible",
          transform: "scaleX(-1)",
          position: "absolute",
          top: 0,
          left: 0,
          zIndex: -100,
        }}
        width={videoElementProps.width}
      />
    </div>
  )
}

export default Camera;
