// Components
import CustomerDetectionHandler from "@/components/customer-detection/CustomerDetectionHandler";
import PusherNotificationsHandler from "@/components/global-components/web-socket-notifications/PusherNotificationsHandler";
import RoutesHandler from "@/components/global-components/routes-handler/RoutesHandler";
import SystemOutageHandler from "@/components/global-components/system-outages/SystemOutageHandler";

// Context
import GlobalContextProvider from "@/context/GlobalContext";

// Node Modules
import Prism from "prismjs";
import TagManager from "react-gtm-module";
import App, { AppProps } from "next/app";
import React, {
  useEffect,
  useState
} from "react";

// Services
import contentService from "@/services/contentService";
import dataLayerService from "@/services/dataLayerService";

// Styles
import "@fortawesome/fontawesome-svg-core/styles.css";
import { config } from "@fortawesome/fontawesome-svg-core";

// Themes
import theme from "@/theme";

// Types
import IAppProps from "@/interfaces/pages/IAppProps";

config.autoAddCss = false;

const initializeTagManager = () => {
  const tagManagerArgs = {
    gtmId: process.env.GA_CONTAINER_ID,
  };

  TagManager.initialize(tagManagerArgs);
};

function sendToGTM({
  name,
  value,
  id,
}) {
  const event = {
    event: "web-vitals",
    event_action: name,
    event_category: "Web Vitals",

    /*
     * The `id` value will be unique to the current page load. When sending
     * multiple values from the same page (e.g. for CLS), Google Analytics can
     * compute a total by grouping on this ID (note: requires `eventLabel` to
     * be a dimension in your report).
     */
    event_label: id,

    /*
     * Google Analytics metrics must be integers, so the value is rounded.
     * For CLS the value is first multiplied by 1000 for greater precision
     * (note: increase the multiplier for greater precision if needed).
     */
    event_value: Math.round(name === "CLS"
      ? value * 1000
      : value),

  }

  dataLayerService.pushEvent(event);
}

/*
 * Will be called once by Next.js for every metric that has to be reported.
 * We don't explicitly call reportWebVitals ourselves
 */
export function reportWebVitals(metric) {
  sendToGTM(metric);
}

const MyApp = ({
  Component,
  pageProps,
}: AppProps<IAppProps>) => {
  const [isSystemOutage, setIsSystemOutage] = useState<boolean>(false);

  useEffect(() => {
    initializeTagManager();
    disableAlerts();

    if (document.location.href.indexOf("component-library") > -1) {
      Prism.highlightAll();
    }
  }, []);

  useEffect(() => {
    try {
      const {
        pageGeneratedOn,
      } = pageProps;

      const generatedTime = Date.parse(pageGeneratedOn);
      const renderTime = new Date();

      const diffInSeconds = Math.floor(Math.abs(renderTime.getTime() - generatedTime) / 1000);
      const formattedTimeDiff = `${Math.floor(diffInSeconds / 60)}:${`${diffInSeconds % 60}`.padStart(2, "0")}`;

      console.info(`Page generation time drift: ${formattedTimeDiff}s`);
      console.info(`Generated at ${pageGeneratedOn}`);
      console.info(`Received at ${renderTime.toISOString()}`);
    } catch (err) {
      console.error("Could not check page generation time drift");
      console.error(err);
    }
  }, []);

  const disableAlerts = (): void => {
    window.alert = (): void => { }
    window.confirm = (): boolean => true;
    window.prompt = (): string => "Disabled"
  }

  const {
    kioskComputerNames,
  } = pageProps.content;

  return (
    <GlobalContextProvider kioskComputerNames={kioskComputerNames}>
      {
        !isSystemOutage && (
          <>
            <CustomerDetectionHandler />
            <Component
              headTitle={pageProps.headTitle}
              {...pageProps}
            />
          </>
        )
      }
      <RoutesHandler />
      <SystemOutageHandler
        isSystemOutage={isSystemOutage}
        setIsSystemOutage={setIsSystemOutage}
      />
      <PusherNotificationsHandler />
      <style
        global
        jsx
      >
        {`
        * { 
            margin: 0;
            padding: 0;
            box-sizing: border-box; 
        }
 
        html,body, #__next {
          height: 100%;
       }
        
        html {
            height: 100%;
            font-size: 10px;
            scroll-behavior: smooth;
            overscroll-behavior-x: none;
        }

        body {
          overscroll-behavior-x: none;
        }
        
        @media (prefers-reduced-motion) {
            html {
                scroll-behavior: auto;
            }
        }
        
        body {
            background: ${theme.brandColors.white};
            color: #54565b;
            display: flex;
            flex-direction: column;
            font-family: Arial;
            justify-content: center;
            margin: 0;
            padding: 0;
            width: 100%;
        }
      
        ::-webkit-scrollbar { width: 10px; }
        ::-webkit-scrollbar-track { background-color: #f1f1f1; }
        ::-webkit-scrollbar-thumb {
            background: #a1a1a1;
            border-radius: 10px;
            cursor: pointer;
        }

        ::-webkit-scrollbar-thumb:hover { background: ${theme.brandColors.darkGray}; }
        ::-webkit-scrollbar-thumb:active { background: ${theme.brandColors.green}; }
        
        @font-face {
            font-family: "RamaGothicSemiBold";
            src: url("/assets/fonts/RamaGothicE_SemiBold-webfont.eot");
            src: url("/assets/fonts/RamaGothicE_SemiBold-webfont.eot?#iefix")
                format("embedded-opentype"),
                url("/assets/fonts/RamaGothicE_SemiBold-webfont.woff")
                format("woff"),
                url("/assets/fonts/RamaGothicE_SemiBold-webfont.ttf")
                format("truetype"),
                url("/assets/fonts/RamaGothicE_SemiBold-webfont.svg#rama_gothic_esemibold")
                format("svg");
            font-weight: normal;
            font-style: normal;
            font-display: swap;
        }

        main { min-height: 600px; }
        footer { flex-grow: 0; }

        h1 {
          color: ${theme.fontColors.black};
          font-family: ${theme.fontFamilies.ramaGothicSemiBold};
          text-transform: uppercase;
          font-size: 8rem;
        }

        h2 {
          color: ${theme.fontColors.black};
          font-family: ${theme.fontFamilies.ramaGothicSemiBold};
          text-transform: uppercase;
          font-size: 6.3rem;
        }

        h3 {
          font-family: ${theme.fontFamilies.ramaGothicSemiBold};
          text-transform: uppercase;
          font-size: 4.8rem;
        }
        
        h4 {
          font-family: ${theme.fontFamilies.ramaGothicSemiBold};
          text-transform: uppercase;
          font-size: 3.2rem;
        }     

        a {
          color: ${theme.brandColors.green};
          text-decoration: none;
        }
        a:hover { text-decoration: underline; }

        pre {
          background: ${theme.brandColors.lightGray};
          border-radius: 15px;
          padding: 1em;
          white-space: normal;
        }
        
        p {
          color: ${theme.textColors.primaryGray};
          font-size: 3.2rem;
          line-height: 1.4;
        }
      
        ul {
          list-style: none;
          padding: 0;
        }
        
        svg {
          height: 1em;
          width: 1em;
        }
        
        .underline { text-decoration: underline; }          
        .uppercase { text-transform: uppercase; }
        
        .fade-in { animation: fadein 0.5s ease-in; }
        @keyframes fadein {
        0% { opacity: 0; }
        100% { opacity: 1; }
        }
        
        .arial { font-family: ${theme.fontFamilies.arial}; }                   
        }        
      `}
      </style>
    </GlobalContextProvider>
  );
};

MyApp.getInitialProps = async appContext => {
  const {
    Component,
  } = appContext;

  let headTitle = "Self Storage Units at Extra Space Storage: Mini Storage Facilities";

  const pageGetInitialPropsPromise = App.getInitialProps(appContext);

  const entryId = Component.pageContentEntryId;
  const webEntryId = Component.webPageContentEntryId;

  let pageContentPromise = entryId
    ? contentService.getKioskContent(entryId)
    : null;

  const webPageContentPromise = webEntryId
    ? contentService.getWebContent(webEntryId)
    : null;

  const {
    pageProps,
  } = await pageGetInitialPropsPromise;

  if (!pageContentPromise && pageProps && pageProps.pageContentEntryId) {
    pageContentPromise = contentService.getKioskContent(pageProps.pageContentEntryId);
  }

  let content;
  if (pageContentPromise) {
    content = await pageContentPromise;

    if (content.title) {
      headTitle = content.title;
    }
    if (webPageContentPromise) {
      const webContent = await webPageContentPromise;

      content = {
        ...content,
        webContent,
      };
    }
  }

  return {
    pageProps: {
      ...pageProps,
      content,
      headTitle,
      pageGeneratedOn: (new Date()).toISOString(),
    },
  };
};

export default MyApp;
