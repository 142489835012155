// Node Modules
import React, {
  createContext,
  useContext,
  useState,
} from "react";

// Types
import IBaseContextProvider from "@/interfaces/context/IBaseContextProvider";
import IStoreDetailsContext from "@/interfaces/store-details/IStoreDetailsContext";
import {
  IHoursOfOperationDetails,
  IStoreAddress
} from "@/interfaces/splash-screen";

const defaultAddress: IStoreAddress = {
  city: "",
  formattedAddress: null,
  line1: "",
  line2: "",
  line3: "",
  line4: "",
  postalCode: "",
  stateAbbreviation: "",
  stateName: "",
}

const StoreDetailsContext = createContext<IStoreDetailsContext | undefined>(undefined);

const useStoreDetailsContext = () => {
  const context = useContext(StoreDetailsContext);
  if (context === undefined) {
    throw new Error("useStoreDetailsContext must be used within a StoreDetailsContextProvider");
  }

  return context;
};

const StoreDetailsContextProvider = ({
  children,
}: IBaseContextProvider) => {
  const [address, setAddress] = useState<IStoreAddress>(defaultAddress);
  const [adminFee, setAdminFee] = useState<number>(0);
  const [hasAvailableInventory, setHasAvailableInventory] = useState<boolean>(false);
  const [hoursOfOperation, setHoursOfOperation] = useState<IHoursOfOperationDetails[]>([]);
  
  const setStoreDetails = (address: IStoreAddress, adminFee:number, hasAvailableInventory: boolean, hoursOfOperation: IHoursOfOperationDetails[]) => {
    setAddress(address);
    setAdminFee(adminFee);
    setHasAvailableInventory(hasAvailableInventory);
    setHoursOfOperation(hoursOfOperation);
  }

  const storeDetailsContext: IStoreDetailsContext = {
    address,
    adminFee,
    hasAvailableInventory,
    hoursOfOperation,
    setStoreDetails,
    stateAbbreviation: address?.stateAbbreviation ?? "",
  };

  return (
    <StoreDetailsContext.Provider
      value={storeDetailsContext}
    >
      {children}
    </StoreDetailsContext.Provider>
  );
};

export {
  StoreDetailsContext,
  StoreDetailsContextProvider,
  useStoreDetailsContext,
};
