// Components
import AudioCueHandler from "@/components/audio-cue/AudioCueHandler";
import CameraCustomerDetectionHandler from "@/components/customer-detection/CameraCustomerDetectionHandler";
import ProximitySensorHandler from "@/components/customer-detection/proximity-sensor/ProximitySensorHandler";

// Context
import { useCustomerDetectionContext } from "@/context/CustomerDetectionContext";

// Enum
import { CameraConfig } from "@/enums/optimizely/HardwareConfig/CameraConfig";
import { ProxSensorConfig } from "@/enums/optimizely/HardwareConfig/ProxSensorConfig";

// Node Modules
import { useDecision } from "@optimizely/react-sdk";
import {
  useEffect,
  useState
} from "react";

// Scripts
import { dataDogLogMessages } from "@/scripts/constant-types/logging/dataDogLogMessages";
import {
  isCustomerDetectedByCamera,
  isCustomerDetectedByProxSensor,
  isCustomerEngagedByCamera,
  isCustomerEngagedByProxSensor,
  isCustomerNotPresentByCamera,
  isCustomerNotPresentByProxSensor,
  isItTimeToClearCurrentDetectionSession,
  registerCustomerDetectedByCameraEvent,
  registerCustomerDetectedByProxSensorEvent,
  registerCustomerDisengagedByCameraEvent,
  registerCustomerDisengagedByProxSensorEvent,
  registerCustomerEngagedByCameraEvent,
  registerCustomerEngagedByProxSensorEvent,
  registerCustomerInteractingWithKioskEvent
} from "@/scripts/customerDetectionHelper";

// Services
import { postDataDogMetrics } from "@/services/dataDogMetricsService";

// Types
import ICameraReading from "@/interfaces/customer-detection/camera/ICameraReading";
import ICustomerDetectionEvent from "@/interfaces/customer-detection/ICustomerDetectionEvent";
import IProximitySensorReading from "@/interfaces/hardware-integration-module/proximity-sensor/IProximitySensorReading";
import { useDeviceInformationContext } from "@/context/device-information/DeviceInformationContext";

const CustomerDetectionHandler = (): JSX.Element => {
  const [cameraReadings, setCameraReadings] = useState<ICameraReading[]>([]);
  const [proximitySensorReadings, setProximitySensorReadings] = useState<IProximitySensorReading[]>([]);
  const [customerDetectionEvent, setCustomerDetectionEvent] = useState<ICustomerDetectionEvent>(undefined);
  const [isCameraEnabledForCustomerDetectionOnThisKiosk, setIsCameraEnabledForCustomerDetectionOnThisKiosk] = useState<boolean>(false);
  const [isCameraAccessibleFromThisBrowser, setIsCameraAccessibleFromThisBrowser] = useState<boolean>(false);
  const [isProxSensorEnabledForThisKiosk, setIsProxSensorEnabledForThisKiosk] = useState<boolean>(false);
  const [isProxSensorOnlineForThisKiosk, setIsProxSensorOnlineForThisKiosk] = useState<boolean>(false);
  const [mediaStream, setMediaStream] = useState<MediaStream | null>(null);
  const {
    isCustomerInteractingWithTheScreen,
    proximitySensorThreshold,
    setIsCustomerInteractingWithTheScreen,
    setShouldPlayAudio,
    setShouldRunInteractionCall,
  } = useCustomerDetectionContext();

  const {
    deviceInformation,
  } = useDeviceInformationContext();

  const [cameraDecision] = useDecision(CameraConfig.Variation, {
    autoUpdate: true,
  });

  const [proxDecision] = useDecision(ProxSensorConfig.Variation, {
    autoUpdate: true,
  })

  const clearCustomerDetectionEvent = (): void => {
    setCustomerDetectionEvent(undefined);
    setIsCustomerInteractingWithTheScreen(false);
    setShouldPlayAudio(false);
  };

  useEffect(() => {
    const hardwareConfigurationCheck = async (): Promise<void> => {
      const isCameraEnabledForCustomerDetectionOnThisKiosk = cameraDecision?.enabled && cameraDecision?.variationKey === CameraConfig.Variation;
      const isProxSensorEnabledForThisKiosk = proxDecision?.enabled && proxDecision?.variationKey === ProxSensorConfig.Variation;
      setIsProxSensorEnabledForThisKiosk(isProxSensorEnabledForThisKiosk)
      setIsCameraEnabledForCustomerDetectionOnThisKiosk(isCameraEnabledForCustomerDetectionOnThisKiosk)
    };
    hardwareConfigurationCheck();
  }, [cameraDecision, proxDecision]);

  useEffect(() => {
    const {
      kioskId,
      kioskComputerName,
      storeNumber,
    } = deviceInformation;

    const host = `https://${window.location.host}`;

    const touchStartEvent = (): void => {
      postDataDogMetrics(kioskId, kioskComputerName, storeNumber, host, dataDogLogMessages.customerDetection.customerScreenTap);
    };

    window.addEventListener("touchstart", touchStartEvent);

    return () => window.removeEventListener("touchstart", touchStartEvent);
  }, []);

  useEffect(() => {
    const isAtLeastOneSensorEnabled: boolean = isCameraEnabledForCustomerDetectionOnThisKiosk || isProxSensorEnabledForThisKiosk;
    let touchStartEvent: () => void;

    if (isAtLeastOneSensorEnabled) {
      touchStartEvent = () => setIsCustomerInteractingWithTheScreen(true);
      window.addEventListener("touchstart", touchStartEvent);
    }

    return () => isAtLeastOneSensorEnabled && window.removeEventListener("touchstart", touchStartEvent);
  }, [isCameraEnabledForCustomerDetectionOnThisKiosk, isProxSensorEnabledForThisKiosk]);

  useEffect(() => {
    const isTimeToClearCurrentDetectionSession = isItTimeToClearCurrentDetectionSession(customerDetectionEvent, isCameraAccessibleFromThisBrowser, isProxSensorOnlineForThisKiosk);

    if (isTimeToClearCurrentDetectionSession) {
      clearCustomerDetectionEvent();
    }
  }, [customerDetectionEvent]);

  useEffect(() => {
    if (isCustomerDetectedByCamera(cameraReadings, customerDetectionEvent)) {
      registerCustomerDetectedByCameraEvent(deviceInformation, cameraReadings, customerDetectionEvent, setCustomerDetectionEvent);
      setShouldPlayAudio(true);
    }

    if (isCustomerEngagedByCamera(cameraReadings, customerDetectionEvent)) {
      registerCustomerEngagedByCameraEvent(customerDetectionEvent, cameraReadings, setCustomerDetectionEvent);
      setShouldRunInteractionCall(true);
    }

    if (isCustomerNotPresentByCamera(cameraReadings)) {
      const shouldTriggerDisengagedEvent: boolean = !!customerDetectionEvent?.session_id &&
        !!customerDetectionEvent?.camera_event.date_started
        && !customerDetectionEvent?.camera_event.date_ended;

      if (shouldTriggerDisengagedEvent) {
        registerCustomerDisengagedByCameraEvent(cameraReadings, customerDetectionEvent, setCustomerDetectionEvent);
        setShouldRunInteractionCall(false);
      }

      setCameraReadings([]);
    }
  }, [cameraReadings]);

  useEffect(() => {
    const proxSensorCalibrationValue: number = proximitySensorThreshold?.averageCalibrationValue;
    if (proximitySensorReadings?.length > 0) {
      if (isCustomerDetectedByProxSensor(proximitySensorReadings, customerDetectionEvent, proxSensorCalibrationValue)) {
        registerCustomerDetectedByProxSensorEvent(deviceInformation, customerDetectionEvent, proximitySensorReadings, proxSensorCalibrationValue, setCustomerDetectionEvent);
        setShouldPlayAudio(true);
      }

      if (isCustomerEngagedByProxSensor(proximitySensorReadings, customerDetectionEvent, proxSensorCalibrationValue)) {
        registerCustomerEngagedByProxSensorEvent(customerDetectionEvent, proximitySensorReadings, setCustomerDetectionEvent);
        setShouldRunInteractionCall(true);
      }

      if (isCustomerNotPresentByProxSensor(proximitySensorReadings, proxSensorCalibrationValue)) {
        const shouldTriggerDisengagedEvent: boolean = !!customerDetectionEvent?.session_id &&
          !!customerDetectionEvent?.proximity_sensor_event.date_started
          && !customerDetectionEvent?.proximity_sensor_event.date_ended;

        if (shouldTriggerDisengagedEvent) {
          registerCustomerDisengagedByProxSensorEvent(customerDetectionEvent, proximitySensorReadings, setCustomerDetectionEvent);
          setShouldRunInteractionCall(false);
        }

        setProximitySensorReadings([]);
      }
    }
  }, [proximitySensorReadings]);

  useEffect(() => {
    if (isCustomerInteractingWithTheScreen) {
      registerCustomerInteractingWithKioskEvent(
        deviceInformation,
        cameraReadings,
        customerDetectionEvent,
        proximitySensorReadings,
        setCustomerDetectionEvent);
    } else {
      clearCustomerDetectionEvent();
    }
  }, [isCustomerInteractingWithTheScreen]);

  return (
    <>
      {
        isProxSensorEnabledForThisKiosk && (
          <ProximitySensorHandler
            setIsProxSensorOnlineForThisKiosk={setIsProxSensorOnlineForThisKiosk}
            setProximitySensorReadings={setProximitySensorReadings}
          />
        )
      }
      {
        isCameraEnabledForCustomerDetectionOnThisKiosk && (
          <CameraCustomerDetectionHandler
            mediaStream={mediaStream}
            setCameraReadings={setCameraReadings}
            setIsCameraAccessibleFromThisBrowser={setIsCameraAccessibleFromThisBrowser}
            setMediaStream={setMediaStream}
          />
        )
      }
      <AudioCueHandler
        customerDetectionEvent={customerDetectionEvent}
      />
    </>
  )
}

export default CustomerDetectionHandler;
