// Node Modules
import { v4 as uuidv4 } from "uuid";

import {
  StatusType,
  isItTimeToRefreshDatadogSession,
  logOnDataDog
} from "@/services/dataDogLoggingService";

// Scripts
import { dataDogLogMessages } from "@/scripts/constant-types/logging/dataDogLogMessages";
import { saveCanvasFrameAsImage } from "@/scripts/fileDownloadHelper";
import {
  convertTimeInSecondsToHumanReadableString,
  getLocalTimestampFromDate
} from "@/scripts/dateHelper";

// Types
import ICameraReading from "@/interfaces/customer-detection/camera/ICameraReading";
import ICustomerDetectionEvent from "@/interfaces/customer-detection/ICustomerDetectionEvent";
import IDeviceInformation from "@/interfaces/device/IDeviceInformation";
import IProximitySensorReading from "@/interfaces/customer-detection/proximity-sensor/IProximitySensorReading";
import {
  Dispatch,
  SetStateAction
} from "react";

const readings = {
  detected: 2,
  disengaged: 15,
  engaged: 5,
}

const createCustomerDetectionEvent = (deviceInformation: IDeviceInformation): ICustomerDetectionEvent => {
  const customerDetectionEvent: ICustomerDetectionEvent = {
    camera_event: {
      camera_readings: [],
      is_customer_detected: false,
      is_customer_disengaged: false,
      is_customer_engaged: false,
    },
    is_customer_interacting: false,
    kiosk_id: deviceInformation.kioskId,
    oz_store_id: deviceInformation.ozStoreId,
    proximity_sensor_event: {
      is_customer_detected: false,
      is_customer_disengaged: false,
      is_customer_engaged: false,
      sensor_readings: [],
    },
    session_id: uuidv4(),
    store_number: deviceInformation.storeNumber,
  };

  return customerDetectionEvent;
};

const hasCustomerDetectionEventStarted = (customerDetectionEvent: ICustomerDetectionEvent): boolean => !!customerDetectionEvent?.session_id;

const isCustomerAlreadyDisengagedByCamera = (customerDetectionEvent: ICustomerDetectionEvent): boolean =>
  hasCustomerDetectionEventStarted(customerDetectionEvent) &&
  !!customerDetectionEvent?.camera_event?.is_customer_detected &&
  !!customerDetectionEvent?.camera_event?.is_customer_disengaged;
  
const isCustomerAlreadyDisengagedByProxSensor = (customerDetectionEvent: ICustomerDetectionEvent): boolean =>
  hasCustomerDetectionEventStarted(customerDetectionEvent) &&
  !!customerDetectionEvent?.proximity_sensor_event?.is_customer_detected &&
  !!customerDetectionEvent?.proximity_sensor_event?.is_customer_disengaged;

const isCustomerDetectedByCamera = (cameraReadings: ICameraReading[], customerDetectionEvent: ICustomerDetectionEvent): boolean =>
  !customerDetectionEvent?.camera_event?.is_customer_detected &&
    cameraReadings.length > 1 &&
    cameraReadings.slice(-readings.detected).every(x => x.is_face_detected);

const isCustomerDetectedByProxSensor = (proxSensorReadings: IProximitySensorReading[], customerDetectionEvent: ICustomerDetectionEvent, proxSensorCalibrationValue: number): boolean =>
  !customerDetectionEvent?.proximity_sensor_event?.is_customer_detected &&
    proxSensorReadings.length > 1 &&
    proxSensorReadings.slice(-readings.detected).every(x => isProxValueGreaterThanThreshold(x.value, proxSensorCalibrationValue));

const isCustomerEngagedByCamera = (cameraReadings: ICameraReading[], customerDetectionEvent: ICustomerDetectionEvent): boolean =>
  hasCustomerDetectionEventStarted(customerDetectionEvent) &&
  !customerDetectionEvent?.camera_event.is_customer_engaged &&
  cameraReadings.length >= readings.engaged &&
  cameraReadings.slice(-readings.engaged).every(x => x.is_face_detected);

const isCustomerEngagedByProxSensor = (proxSensorReadings: IProximitySensorReading[], customerDetectionEvent: ICustomerDetectionEvent, proxSensorCalibrationValue: number): boolean =>
  hasCustomerDetectionEventStarted(customerDetectionEvent) &&
  !customerDetectionEvent?.proximity_sensor_event.is_customer_engaged &&
  proxSensorReadings.length >= readings.engaged &&
  proxSensorReadings.slice(-readings.engaged).every(x => isProxValueGreaterThanThreshold(x.value, proxSensorCalibrationValue));

const isCustomerNotPresentByCamera = (cameraReadings: ICameraReading[]): boolean =>
  cameraReadings.length >= readings.disengaged &&
  cameraReadings.slice(-readings.disengaged).every(x => !x.is_face_detected);
  
const isCustomerNotPresentByProxSensor = (proxSensorReadings: IProximitySensorReading[], proxSensorCalibrationValue: number): boolean =>
  proxSensorReadings.length >= readings.disengaged &&
  proxSensorReadings.slice(-readings.disengaged).every(x => isProxValueLessThanOrEqualToThreshold(x.value, proxSensorCalibrationValue));

const isProxValueGreaterThanThreshold = (value: number, proxSensorCalibrationValue: number): boolean => value > proxSensorCalibrationValue;

const isProxValueLessThanOrEqualToThreshold = (value: number, proxSensorCalibrationValue: number): boolean => value <= proxSensorCalibrationValue;

const isItTimeToClearCurrentDetectionSession = (customerDetectionEvent: ICustomerDetectionEvent, isCameraAccessibleFromThisBrowser: boolean, isProxSensorOnlineForThisKiosk: boolean): boolean => {
  const hasDetectionEventStarted: boolean = hasCustomerDetectionEventStarted(customerDetectionEvent);
  
  if ((!isCameraAccessibleFromThisBrowser && !isProxSensorOnlineForThisKiosk) || !hasDetectionEventStarted) {
    return false;
  }
  
  const requiresDisengagementFromCamera: boolean = isCameraAccessibleFromThisBrowser && !!customerDetectionEvent?.camera_event?.is_customer_detected;
  const requiresDisengagementFromProxSensor: boolean = isProxSensorOnlineForThisKiosk && !!customerDetectionEvent?.proximity_sensor_event?.is_customer_detected;
  const cameraDisengagesAndProxSensorDisengagementNotRequired: boolean =
  requiresDisengagementFromCamera &&
  isCustomerAlreadyDisengagedByCamera(customerDetectionEvent) &&
  !requiresDisengagementFromProxSensor;

  const proxSensorDisengagesAndCameraDisengagementNotRequired: boolean =
  requiresDisengagementFromProxSensor &&
  isCustomerAlreadyDisengagedByProxSensor(customerDetectionEvent) &&
  !requiresDisengagementFromCamera;

  const cameraAndProxSensorDisengaged: boolean =
  requiresDisengagementFromProxSensor && isCustomerAlreadyDisengagedByProxSensor(customerDetectionEvent) &&
  requiresDisengagementFromCamera && isCustomerAlreadyDisengagedByCamera(customerDetectionEvent);

  if (cameraDisengagesAndProxSensorDisengagementNotRequired || proxSensorDisengagesAndCameraDisengagementNotRequired || cameraAndProxSensorDisengaged) {
    return true;
  }

  return false;
};

const registerCustomerDetectedByCameraEvent = (
  deviceInformation: IDeviceInformation,
  cameraReadings: ICameraReading[],
  customerDetectionEventInState: ICustomerDetectionEvent,
  setCustomerDetectionEventInState: Dispatch<SetStateAction<ICustomerDetectionEvent>>
): void => {
  const defaultCustDetectedEvent = hasCustomerDetectionEventStarted(customerDetectionEventInState)
    ? customerDetectionEventInState
    : createCustomerDetectionEvent(deviceInformation);

  const custDetectedEvent: ICustomerDetectionEvent = {
    ...defaultCustDetectedEvent,
    session_started_by: defaultCustDetectedEvent.session_started_by ?? "CAMERA",
    camera_event: {
      ...defaultCustDetectedEvent.camera_event,
      date_started: cameraReadings.find(x => x.is_face_detected).date,
      is_customer_detected: true,
      camera_readings: cameraReadings,
    },
  };

  validateIfNeedToRefreshDatadogSession();
  logOnDataDog(dataDogLogMessages.customerDetection.camera.customerDetected, StatusType.info, {
    event: custDetectedEvent,
  });

  setCustomerDetectionEventInState(custDetectedEvent);
};

const registerCustomerDetectedByProxSensorEvent = (
  deviceInformation:IDeviceInformation,
  customerDetectionEventInState: ICustomerDetectionEvent,
  proxSensorReadings: IProximitySensorReading[],
  proxSensorCalibrationValue: number,
  setCustomerDetectionEventInState: Dispatch<SetStateAction<ICustomerDetectionEvent>>
): void => {
  const defaultCustDetectedEvent = customerDetectionEventInState?.session_id
    ? customerDetectionEventInState
    : createCustomerDetectionEvent(deviceInformation);

  const custDetectedEvent: ICustomerDetectionEvent = {
    ...defaultCustDetectedEvent,
    session_started_by: defaultCustDetectedEvent.session_started_by ?? "PROX_SENSOR",
    proximity_sensor_event: {
      ...defaultCustDetectedEvent.proximity_sensor_event,
      date_started: proxSensorReadings.find(x => isProxValueGreaterThanThreshold(x.value, proxSensorCalibrationValue)).date,
      is_customer_detected: true,
      sensor_readings: proxSensorReadings,
    },
  };

  validateIfNeedToRefreshDatadogSession();
  logOnDataDog(`PROX_SENSOR_CUSTOMER_DETECTED at store: ${deviceInformation.storeNumber} session_id: ${custDetectedEvent.session_id}`, StatusType.info, {
    event: custDetectedEvent,
  });
  
  setCustomerDetectionEventInState(custDetectedEvent);
};

const registerCustomerDisengagedByCameraEvent = (
  cameraReadings: ICameraReading[],
  customerDetectionEventInState: ICustomerDetectionEvent,
  setCustomerDetectionEventInState: Dispatch<SetStateAction<ICustomerDetectionEvent>>
): void => {
  const dateEnded: Date = cameraReadings.slice(-readings.disengaged)[0].date;
  const durationInSeconds: number = (customerDetectionEventInState?.camera_event?.date_started?.getTime() - dateEnded.getTime()) / 1000;
  const duration: string = convertTimeInSecondsToHumanReadableString(durationInSeconds);
  const custDisengagedEvent: ICustomerDetectionEvent = {
    ...customerDetectionEventInState,
    camera_event: {
      ...customerDetectionEventInState.camera_event,
      camera_readings: cameraReadings,
      date_ended: dateEnded,
      duration,
      is_customer_disengaged: true,
    },
    session_ended_by: customerDetectionEventInState.session_ended_by ?? "CAMERA",
  };

  validateIfNeedToRefreshDatadogSession();
  logOnDataDog(dataDogLogMessages.customerDetection.camera.customerDisengaged, StatusType.info, {
    event: custDisengagedEvent,
  });

  setCustomerDetectionEventInState(custDisengagedEvent);
};

const registerCustomerDisengagedByProxSensorEvent = (
  customerDetectionEventInState: ICustomerDetectionEvent,
  proxSensorReadings: IProximitySensorReading[],
  setCustomerDetectionEventInState: Dispatch<SetStateAction<ICustomerDetectionEvent>>
): void => {
  const dateEnded: Date = proxSensorReadings.slice(-readings.disengaged)[0].date;
  const durationInSeconds: number = (customerDetectionEventInState.proximity_sensor_event.date_started.getTime() - dateEnded.getTime()) / 1000;
  const duration: string = convertTimeInSecondsToHumanReadableString(durationInSeconds);
  const custDisengagedEvent: ICustomerDetectionEvent = {
    ...customerDetectionEventInState,
    proximity_sensor_event: {
      ...customerDetectionEventInState.proximity_sensor_event,
      is_customer_disengaged: true,
      date_ended: dateEnded,
      duration,
      sensor_readings: proxSensorReadings,
    },
    session_ended_by: customerDetectionEventInState.session_ended_by ?? "PROX_SENSOR",
  };
  
  validateIfNeedToRefreshDatadogSession();
  logOnDataDog(dataDogLogMessages.customerDetection.proximitySensor.customerDisengaged, StatusType.info, {
    event: custDisengagedEvent,
  });

  setCustomerDetectionEventInState(custDisengagedEvent);
};

const registerCustomerEngagedByCameraEvent = (
  customerDetectionEventInState: ICustomerDetectionEvent,
  cameraReadings: ICameraReading[],
  setCustomerDetectionEventInState: Dispatch<SetStateAction<ICustomerDetectionEvent>>
): void => {
  const custEngagedEvent: ICustomerDetectionEvent = {
    ...customerDetectionEventInState,
    camera_event: {
      ...customerDetectionEventInState.camera_event,
      is_customer_engaged: true,
      camera_readings: cameraReadings,
    },
  };
  
  validateIfNeedToRefreshDatadogSession();
  logOnDataDog(dataDogLogMessages.customerDetection.camera.customerEngaged, StatusType.info, {
    event: custEngagedEvent,
  });

  if (shouldSaveImagesInStorage(custEngagedEvent.store_number)) {
    saveImagesInPc(cameraReadings);
  }

  setCustomerDetectionEventInState(custEngagedEvent);
};

const registerCustomerEngagedByProxSensorEvent = (
  customerDetectionEventInState: ICustomerDetectionEvent,
  proxSensorReadings: IProximitySensorReading[],
  setCustomerDetectionEventInState: Dispatch<SetStateAction<ICustomerDetectionEvent>>
): void => {
  const custEngagedEvent: ICustomerDetectionEvent = {
    ...customerDetectionEventInState,
    proximity_sensor_event: {
      ...customerDetectionEventInState.proximity_sensor_event,
      is_customer_engaged: true,
      sensor_readings: proxSensorReadings,
    },
  };

  validateIfNeedToRefreshDatadogSession();
  logOnDataDog(dataDogLogMessages.customerDetection.proximitySensor.customerEngaged, StatusType.info, {
    event: custEngagedEvent,
  });

  setCustomerDetectionEventInState(custEngagedEvent);
};

const registerCustomerInteractingWithKioskEvent = (
  deviceInformation: IDeviceInformation,
  cameraReadings: ICameraReading[],
  customerDetectionEventInState: ICustomerDetectionEvent,
  proxSensorReadings: IProximitySensorReading[],
  setCustomerDetectionEventInState: Dispatch<SetStateAction<ICustomerDetectionEvent>>
): void => {
  const defaultCustDetectedEvent = hasCustomerDetectionEventStarted(customerDetectionEventInState)
    ? customerDetectionEventInState
    : createCustomerDetectionEvent(deviceInformation);

  const custInteractingEvent: ICustomerDetectionEvent = {
    ...defaultCustDetectedEvent,
    customer_interaction_date_started: new Date(),
    is_customer_interacting: true,
    proximity_sensor_event: {
      ...defaultCustDetectedEvent?.proximity_sensor_event,
      sensor_readings: proxSensorReadings,
    },
    camera_event: {
      ...defaultCustDetectedEvent?.camera_event,
      camera_readings: cameraReadings,
    },
    session_started_by: defaultCustDetectedEvent.session_started_by ?? "CUST_INTERACTION",
  };
  
  validateIfNeedToRefreshDatadogSession();
  logOnDataDog(dataDogLogMessages.customerDetection.customerInteracting, StatusType.info, {
    event: custInteractingEvent,
  });

  setCustomerDetectionEventInState(custInteractingEvent);
};

const saveImagesInPc = (cameraReadings: ICameraReading[]): void => {
  cameraReadings.forEach(reading => {
    if (reading.is_face_detected && reading.canvas_frame) {
      const fileName: string = `Camera_engaged_${getLocalTimestampFromDate(reading.date)}.webp`
      saveCanvasFrameAsImage(reading.canvas_frame, fileName);
    }
  })
};

const shouldSaveImagesInStorage = (storeNumber: string) => {
  const envVariable = process.env.CAMERA_DETECTION_SAVE_IMAGES_IN_STORAGE;
  const values = envVariable && envVariable.includes("|")
    ? envVariable.split("|")
    : [];

  const saveImages = values.length > 1
    && values[0].toUpperCase() === "TRUE"
    && storeNumber
    && values.includes(storeNumber);

  return saveImages;
};

const validateIfNeedToRefreshDatadogSession = (): void => {
  const shouldRefreshDatadogSession: boolean = isItTimeToRefreshDatadogSession();
  if (shouldRefreshDatadogSession) {
    const keyDownEvent: any = new KeyboardEvent("keydown", {
      key: "Shift",
    });

    keyDownEvent.__ddIsTrusted = true;
    document.dispatchEvent(keyDownEvent);
  }
}

export {
  hasCustomerDetectionEventStarted,
  isCustomerAlreadyDisengagedByCamera,
  isCustomerAlreadyDisengagedByProxSensor,
  isCustomerDetectedByCamera,
  isCustomerDetectedByProxSensor,
  isCustomerEngagedByCamera,
  isCustomerEngagedByProxSensor,
  isCustomerNotPresentByCamera,
  isCustomerNotPresentByProxSensor,
  isItTimeToClearCurrentDetectionSession,
  registerCustomerDetectedByCameraEvent,
  registerCustomerDetectedByProxSensorEvent,
  registerCustomerDisengagedByProxSensorEvent,
  registerCustomerDisengagedByCameraEvent,
  registerCustomerEngagedByCameraEvent,
  registerCustomerEngagedByProxSensorEvent,
  registerCustomerInteractingWithKioskEvent,
  shouldSaveImagesInStorage,
};

